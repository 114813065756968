import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Alert, Button } from 'reactstrap';
import { faExclamationTriangle, faSpinner, faSync } from '@fortawesome/free-solid-svg-icons';
import { faLongArrowLeft, faPencil } from './shared/CustomIcons';
import LoginAsUser from '../containers/User/LoginAsUser';
import AccessMetrics from './User/AccessMetrics';
import AccountDetails from './User/AccountDetails';
import ContactDetails from './User/ContactDetails';
import YearlyMetrics from './User/YearlyMetrics';
import SubscriptionManagement from '../containers/UserSubscriptionManagement';
import UserNotFound from './UserNotFound';
import Api from '../utilities/Api';


class User extends Component {
  constructor(props) {
    super(props);
    this.state = { resettingDemoData: false, resettingDemoDataError: null };
  }

  componentDidMount() {
    if (!this.props.user.account_metrics) {
      this.props.requestSingleUser(this.props.userId);
    }
  }

  componentDidUpdate() {
    if (this.props.user && this.props.user.dealer_demonstration === true) {
      this.props.navigate(`/demonstrations/${this.props.user.id}`);
    }
  }

  async resetDemonstrationAccount() {
    try {
      this.setState({ resettingDemoData: true, resettingDemoDataError: null });
      await Api.postAuthenticated(`/users/${this.props.user.id}/reset_demonstration_account`);
    } catch (error) {
      if (error.response && error.response.data) {
        const { response } = error;
        this.setState({ resettingDemoDataError: response.data.error });
      } else {
        this.setState({ resettingDemoDataError: 'Something Went Wrong. Please Try Again' });
      }
    } finally {
      this.setState({ resettingDemoData: false });
    }
  }

  render() {
    const { user } = this.props;

    if (this.state.resettingDemoData) {
      return (
        <div style={{ minHeight: '60vh', opacity: 0.6 }} className="d-flex flex-column align-items-center justify-content-center">
          <div style={{ fontSize: 50 }}>
            <FontAwesomeIcon pulse icon={faSpinner} />
          </div>
          <p className="font-weight-bold">{ this.state.resettingDemoDataError ? this.state.resettingDemoDataError : 'Resetting Demonstration Data Account....'}</p>
        </div>
      );
    }

    if (isEmpty(user)) return <UserNotFound />;

    if (!this.props.user.account_metrics) {
      return (
        <div style={{ minHeight: '60vh', opacity: 0.6 }} className="d-flex flex-column align-items-center justify-content-center">
          <div style={{ fontSize: 50 }}>
            <FontAwesomeIcon pulse icon={faSpinner} />
          </div>
          Fetching User Metrics Data...
        </div>
      );
    }

    return (
      <section className="user">
        { !user.consultant_has_sign_in_access
          && (
          <Alert color="warning" className="user-form--error">
            <FontAwesomeIcon icon={faExclamationTriangle} />
            {' '}
            The user has revoked sign in access to their account.
          </Alert>
          ) }
        <NavLink exact to="/" className="d-block mb-4">
          <FontAwesomeIcon icon={faLongArrowLeft} />
          {' '}
          Return to all users
        </NavLink>
        <Row noGutters className="justify-content-between">
          <Col xs="4" md="auto">
            <h1>{user.name || 'No Name'}</h1>
            <h3 className="user--email"><a href={`mailto:${user.email}`}>{user.email}</a></h3>
          </Col>
          <Col xs="4" md="auto" className="mt-3 mt-md-0 d-flex flex-row align-items-center">
            <LoginAsUser user={user} />
            <NavLink className="btn btn-primary border-0" exact to={`/users/${user.id}/edit`}>
              <FontAwesomeIcon icon={faPencil} className="mr-2" />
              <b>Edit</b>
              {' '}
              User
            </NavLink>
            { user.dealer_demonstration && (
              <Button type="submit" className="shadow-sm btn btn-secondary ml-2" onClick={() => this.resetDemonstrationAccount()} style={{ cursor: 'pointer' }}>
                <b>Reset User Data</b>
                {' '}
                <FontAwesomeIcon
                  icon={faSync}
                />
              </Button>
            )}
          </Col>
        </Row>
        <div>
          <AccessMetrics user={user} />
          <ContactDetails user={user} />
          <AccountDetails userID={user.id} accountMetrics={user.account_metrics} />
          <SubscriptionManagement user={user} />
          <YearlyMetrics accountMetrics={user.account_metrics} />
        </div>
      </section>
    );
  }
}

User.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    name: PropTypes.string,
    password: PropTypes.string,
    cell_phone: PropTypes.string,
    current_sign_in_at: PropTypes.string,
    weekly_active_user: PropTypes.bool,
    monthly_active_user: PropTypes.bool,
    inactive_user: PropTypes.bool,
    account_metrics: PropTypes.shape({}),
    consultant_has_sign_in_access: PropTypes.bool,
    dealer_demonstration: PropTypes.bool.isRequired,
  }),
  requestSingleUser: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  navigate: PropTypes.func.isRequired,
};

User.defaultProps = {
  user: {},
};

export default User;
